import { useMyContext } from '../../contexts/StateHolder';
import { useTranslation } from 'react-i18next';

// Renders contentRatings of chosen item
const LanguageSelectMobile = (props) => {
  const { t, i18n } = useTranslation();

  const { closeHamMenu, setLanguage, allLanguages } = useMyContext();

  const changeLanguage = (lang) => {
    if (i18n.language === lang) return;
    i18n.changeLanguage(lang);
    setLanguage(lang);
    closeHamMenu();
  };

  return (
    <div>
      <div className='categories_info'>
        {allLanguages.map((el, i) => (
          <div
            className={
              el.value === i18n.language
                ? 'activeProfileHeader  '
                : 'inactiveProfileHeader '
            }
            key={el + i}
            onClick={() => {
              changeLanguage(el.value);
            }}
          >
            {el.name}
            {allLanguages.length - 1 !== i && ' /'}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelectMobile;
